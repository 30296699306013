<template>
  <div class="project-tasks">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style lang="scss" scoped>
.project-tasks {
}
</style>
